import {useMemo} from 'react';
import InView from 'react-intersection-observer';
import {useAppSelector} from 'ducks/hooks';
import usePlaceHome from 'hooks/usePlaceHome';
import useFavorite from 'hooks/useFavorite';
import DiscoverySection from './DiscoverySection';
import DiscoveryRowPoiItem from './DiscoveryRowPoiItem';

import s from '../../../styles/components/tplacehome/TPlaceDiscovery.module.scss';

const DiscoveryWeekend = (props: {logParam: any}) => {
  const {tplacehome, userInfo} = useAppSelector((state) => state);
  const placehomeHook = usePlaceHome();

  const poiList = useMemo(() => tplacehome.discoveryData.result.data.weekendList, [tplacehome]);
  const {checkIsFavorite} = useFavorite({list: poiList});

  return (
    <DiscoverySection mainTitle={'#주말에 가볼만한 곳'} subTitle={'내 취향을 담은'} pb={14}>
      <InView
        onChange={(isView) =>
          isView && placehomeHook.sendEventDiscovery('view.weekend_outing_poi', props.logParam)
        }
      >
        <ul className={s.row_list}>
          {poiList.map((item, index) => {
            return (
              <li key={index} className={s.cell}>
                <DiscoveryRowPoiItem
                  data={item}
                  onClickDetail={() =>
                    placehomeHook.sendEventDiscovery('tap.weekend_outing_poi', {
                      euk: userInfo.euk,
                      pkey: item.pkey,
                    })
                  }
                  isFavorite={checkIsFavorite(item)}
                />
              </li>
            );
          })}
        </ul>
      </InView>
    </DiscoverySection>
  );
};

export default DiscoveryWeekend;

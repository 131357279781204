import ImageLoader from 'components/ImageLoader';
import {TTPlaceCurationType3} from 'ducks/remote/type';
import {moveByLandingType} from 'utils/url';
import usePlaceHome from 'hooks/usePlaceHome';

import s from 'styles/components/tplacehome/TPlaceCurationType3.module.scss';

type TProps = {
  item: TTPlaceCurationType3;
};

const TPlaceCurationType3 = ({item}: TProps) => {
  const placehomeHook = usePlaceHome();
  return (
    <div className={s.wrap}>
      <h4 dangerouslySetInnerHTML={{__html: item.title}} />
      {item.list.length > 0 && (
        <ul className={s.list} data-flicking-ignore="true">
          {item.list.map((v, i) => (
            <li
              key={i}
              className={s.item}
              onClick={() => {
                placehomeHook.sendCurrationTapEvent(item.type, item.title, i);
                moveByLandingType(v.landingType, v.landingUrl);
              }}
            >
              <ImageLoader src={v.thumbnailUrl} alt={'이미지'} className={s.image} />
            </li>
          ))}
          {/* ios에서 margin을 스크롤영역으로 인식하지 않음. 여백을 주기위함 */}
          <li className={s.spacer} />
        </ul>
      )}
    </div>
  );
};

export default TPlaceCurationType3;

import {AdBanner} from 'components/AdBanner';
import {ReloadResume} from 'components/ReloadResume';
import {
  EAdCode,
  ENaverDaUnitId,
  ETMapBannerCode,
  isBannerTestEnv,
  RANKING_BANNER_AD_STEP,
} from 'constant/Ads';
import {useAppSelector} from 'ducks/hooks';

import s from 'styles/components/ranking/RankingAdBanner.module.scss';

type TProps = {
  isFirst: boolean;
};

const RankingAdBanner = ({isFirst}: TProps) => {
  const {map, isLandscape} = useAppSelector((state) => ({
    map: state.map,
    isLandscape: state.layout.appSize.isLandscape,
  }));

  if (isLandscape) {
    return null;
  }

  return (
    <div className={s.wrap}>
      <ReloadResume>
        <AdBanner
          visibleLandscape={false}
          adCode={isFirst ? EAdCode.RANKING_FIRST : EAdCode.RANKING_SECOND}
          adTypeStep={RANKING_BANNER_AD_STEP}
          adTypeOption={{
            inner: {
              inventoryCode: isFirst
                ? ETMapBannerCode.RANKING_FIRST
                : ETMapBannerCode.RANKING_SECOND,
            },
            naver: {
              adUnitId: isBannerTestEnv
                ? ENaverDaUnitId.TEST
                : isFirst
                ? ENaverDaUnitId.RANKING_FIRST
                : ENaverDaUnitId.RANKING_SECOND,
            },
          }}
          bannerLonLat={map.lastCachedCenter}
        />
      </ReloadResume>
    </div>
  );
};

export default RankingAdBanner;
